<template>
  <Main>
    <div v-if="accountData">
      <sdPageHeader>
        <template v-slot:title>
          <div class="account__wrapper">
            <p class="account__title">
              {{ i18n.t('accounts.accountTitleName', { msg: i18n.t(`accounts.${accountData.kind}Name`) }) }}
            </p>
            <p class="account__subtitle" v-if="!isPc">
              <span>
                {{ `${i18n.t('accounts.amount')}` }}
                <br />
              </span>
              {{ i18n.n(+accountData.amount, 'decimal') }} <span>{{ accountData.amount_currency }}</span>
            </p>
            <sdButton type="success"> </sdButton>
          </div>
        </template>

        <template v-slot:buttons>
          <div class="account__wrapper">
            <p class="account__subtitle" v-if="isPc">
              <span>
                {{ `${i18n.t('accounts.amount')}` }}
                <br />
              </span>
              {{ i18n.n(+accountData.amount, 'decimal') }} <span>{{ accountData.amount_currency }}</span>
            </p>

            <div class="account__buttons" v-if="accountData.kind === 'personal'">
              <sdButton class="account__button" @click="openModal('deposit')" size="large" type="success" raised>
                {{ i18n.t('start.deposit') }}
              </sdButton>
              <sdButton class="account__button" @click="openModal('withdrawal')" size="large" type="primary" raised>
                {{ i18n.t('start.withdrawal') }}
              </sdButton>
            </div>
            <div class="account__buttons" v-else-if="accountData.kind === 'partner'">
              <sdButton class="account__button" size="large" @click="openModal('transfer')" type="success" raised>
                {{ i18n.t('start.transfer') }}
              </sdButton>
              <sdButton class="account__button" @click="openModal('withdrawal')" size="large" type="primary" raised>
                {{ i18n.t('start.withdrawal') }}
              </sdButton>
            </div>
            <div class="account__buttons" v-else-if="accountData.kind !== 'point'">
              <sdButton class="account__button" size="large" @click="openModal('buy')" type="success" raised>
                {{ i18n.t('start.buy') }}
              </sdButton>
              <a-tooltip placement="top" :title="i18n.t('accounts.token.send_is_blocked')">
                <sdButton class="account__button" size="large" type="primary" raised>
                  <!-- @click="openModal('send')" -->
                  {{ i18n.t('start.sell') }}
                </sdButton>
              </a-tooltip>
            </div>
          </div>
        </template>
      </sdPageHeader>

      <sdCards headless v-if="accountData.kind == 'point'">
        <sdHeading as="h5">{{ i18n.t('accounts.point.title') }} </sdHeading>
        <p class="point-account__info">{{ i18n.t('accounts.point.welcome', 1) }}</p>
        <a-collapse v-model:activeKey="activeKeyInfo">
          <a-collapse-panel key="4" :header="i18n.t('accounts.point.tasks')" :disabled="false" class="point-task">
            <TaskCard v-for="task of tasks" :key="task.key" :task="task" />
          </a-collapse-panel>
          <a-collapse-panel key="1" :header="i18n.t('accounts.point.programTitle')">
            <div v-html="i18n.t('accounts.point.programText')"></div>
          </a-collapse-panel>
          <a-collapse-panel key="2" :header="i18n.t('accounts.point.pointsTitle')" :disabled="false">
            <div v-html="i18n.t('accounts.point.pointsText')"></div>
          </a-collapse-panel>
          <a-collapse-panel key="3" :header="i18n.t('accounts.point.useTitle')" :disabled="false">
            <div v-html="i18n.t('accounts.point.useText')"></div>
          </a-collapse-panel>
        </a-collapse>
      </sdCards>

      <a-tabs @change="changeTab" v-model:activeKey="activeKey" size="large" type="card">
        <a-tab-pane key="1" :tab="i18n.t('transactions.title')">
          <TableWrapper class="table-data-view table-responsive" v-if="transactionsData">
            <a-table :dataSource="dataSourceTransactions" :pagination="false" :columns="columnsTransactions">
              <template #operableType="record">
                <p v-if="record.record.comment">{{ record.record.comment }}</p>
                <p v-else>
                  {{ i18n.t('transactions.' + record.record.operable_type) }}
                  {{
                    record.record.operable_type === 'Transfer'
                      ? i18n.t('transactions.from') +
                        i18n.t('transactions.' + record.record.operation_info.account_from.kind + 'Name', 1) +
                        i18n.t('transactions.to') +
                        i18n.t('transactions.' + record.record.operation_info.account_to.kind + 'Name', 2)
                      : ''
                  }}
                  {{
                    record.record.operable_type === 'Deposit'
                      ? i18n.t(
                          'transactions.' +
                            (record.record.operation_info ? record.record.operation_info.kind : 'cancelWithdrawal'),
                          {
                            tokens: record.record?.operation_info?.amount,
                            name: record.record?.operation_info?.user?.full_name,
                            ref: record.record?.operation_info?.user?.user_referal,
                          },
                        )
                      : ''
                  }}
                  {{
                    record.record.operable_type === 'Withdrawal' && record.record.operation_info
                      ? i18n.t('transactions.onWallet') + '"' + record.record.operation_info.name + '"'
                      : record.record.operable_type === 'Withdrawal'
                      ? i18n.t('transactions.fromAdmin')
                      : ''
                  }}
                  {{
                    record.record.operable_type === 'Exchange'
                      ? i18n.t('transactions.ExchangeTokens', {
                          usd: Number(record.record.operation_info.amount_from),
                          mth: record.record.operation_info.amount_to,
                        })
                      : ''
                  }}
                  {{
                    record.record.operable_type === 'DepositPoint'
                      ? i18n.t(
                          'transactions.point.' + record.record.operation_info.kind,
                          {
                            amount: record.record.amount_operation,
                          },
                          2,
                        )
                      : ''
                  }}
                </p>
              </template>
              <template #amount_operation="record">
                <p>
                  {{ `${record.record.amount_operation} ${record.record.operation_currency}` }}
                </p>
              </template>
            </a-table>
            <div style="text-align: right; margin-bottom: 20px">
              <a-pagination
                :default-page-size="pageSize"
                v-model:pageSize="pageSize"
                :show-size-changer="true"
                v-model:current="transactionsMeta.current"
                :total="transactionsMeta.count"
                @change="onChangeTransactions"
              >
                <template #buildOptionText="props">
                  <span>{{ props.value }}/{{ i18n.t('start.page') }}</span>
                </template>
              </a-pagination>
            </div>
          </TableWrapper>
          <sdCards headless v-else>
            <a-skeleton active :paragraph="{ rows: 10 }" />
          </sdCards>
        </a-tab-pane>
        <a-tab-pane
          key="2"
          :tab="i18n.t('accounts.coinPaymentsDeposit')"
          v-if="accountData.kind == 'personal'"
          @change="onChangePayments"
        >
          <TableWrapper class="table-data-view table-responsive" v-if="coinPaymentsData">
            <a-table :dataSource="dataSourcePayments" :pagination="false" :columns="columnsPayments">
              <template #buildOptionText="props">
                <span>{{ props.value }}/{{ i18n.t('start.page') }}</span>
              </template>
              <template #customTitleStatus>
                <div style="display: flex; cursor: pointer" @click="sortTablePayments('operation_status_text')">
                  <span>
                    {{ i18n.t('start.operation_status_text') }}
                  </span>
                  <span style="margin-left: 10px">
                    <font-awesome-icon
                      :icon="faSort"
                      size="1x"
                      style="textshadow: '0 1px 0 rgba(0, 0, 0, 0.1)'"
                      v-if="linkValue != 'operation_status_text'"
                    />
                    <div v-else>
                      <font-awesome-icon
                        :icon="faSortUp"
                        size="1x"
                        style="textshadow: '0 1px 0 rgba(0, 0, 0, 0.1)'"
                        v-if="order == 'asc'"
                      />
                      <font-awesome-icon
                        :icon="faSortDown"
                        size="1x"
                        style="textshadow: '0 1px 0 rgba(0, 0, 0, 0.1)'"
                        v-else
                      />
                    </div>
                  </span>
                </div>
              </template>
              <template #customTitleDate>
                <div style="display: flex; cursor: pointer" @click="sortTablePayments('created_at')">
                  <span>
                    {{ i18n.t('start.created_at') }}
                  </span>
                  <span style="margin-left: 10px">
                    <font-awesome-icon
                      :icon="faSort"
                      size="1x"
                      style="textshadow: '0 1px 0 rgba(0, 0, 0, 0.1)'"
                      v-if="linkValue != 'created_at'"
                    />
                    <div v-else>
                      <font-awesome-icon
                        :icon="faSortUp"
                        size="1x"
                        style="textshadow: '0 1px 0 rgba(0, 0, 0, 0.1)'"
                        v-if="order == 'asc'"
                      />
                      <font-awesome-icon
                        :icon="faSortDown"
                        size="1x"
                        style="textshadow: '0 1px 0 rgba(0, 0, 0, 0.1)'"
                        v-else
                      />
                    </div>
                  </span>
                </div>
              </template>
              <template #operation_id="record">
                <p
                  @click="openModalDeposit(record.record.id)"
                  style="color: #1890ff; cursor: pointer"
                  v-if="
                    record.record.operation_status_text == 'waiting' ||
                    record.record.operation_status_text == 'Waiting for confirms...' ||
                    record.record.operation_status_text == 'Waiting for confirms' ||
                    record.record.operation_status_text == 'Waiting for buyer funds' ||
                    record.record.operation_status_text == 'Waiting for buyer funds...'
                  "
                >
                  {{ record.record.operation_id }}
                </p>
                <p v-else>
                  {{ record.record.operation_id }}
                </p>
              </template>
              <template #exchange_amount="record">
                {{ record.record.exchange_amount }} {{ record.record.exchange_currency }}
              </template>
              <template #exchange_rate="record">
                {{ record.record.exchange_rate }}
              </template>
              <template #request_amount="record"> {{ record.record.request_amount }} </template>
              <template #operation_status_text="record">
                {{ i18n.t('status.' + record.record.operation_status_text) }}
              </template>
              <template #created_at="record">
                {{ i18n.d(record.record.created_at, 'long') }}
              </template>
            </a-table>
            <div style="text-align: right; margin-bottom: 20px">
              <a-pagination
                :default-page-size="pageSize"
                v-model:pageSize="pageSize"
                :show-size-changer="true"
                v-model:current="paymentsMeta.current"
                :total="paymentsMeta.count"
                @change="onChangePayments"
              >
                <template #buildOptionText="props">
                  <span>{{ props.value }}/{{ i18n.t('start.page') }}</span>
                </template>
              </a-pagination>
            </div>
          </TableWrapper>
          <sdCards headless v-else>
            <a-skeleton active :paragraph="{ rows: 10 }" />
          </sdCards>
        </a-tab-pane>
        <a-tab-pane
          key="3"
          :tab="i18n.t('transactions.withdrawals')"
          v-if="accountData.kind == 'personal'"
          @change="onChangePayments"
        >
          <TableWrapper class="table-data-view table-responsive" v-if="withdrawalsData">
            <a-table :dataSource="dataSourceWithdrawals" :pagination="false" :columns="columnsWithdrawals">
              <template #buildOptionText="props">
                <span>{{ props.value }}/{{ i18n.t('start.page') }}</span>
              </template>
            </a-table>
            <div style="text-align: right; margin-bottom: 20px">
              <a-pagination
                :default-page-size="pageSize"
                v-model:pageSize="pageSize"
                :show-size-changer="true"
                v-model:current="withdrawalsMeta.current"
                :total="withdrawalsMeta.count"
                @change="onChangeWithdrawals"
              >
                <template #buildOptionText="props">
                  <span>{{ props.value }}/{{ i18n.t('start.page') }}</span>
                </template>
              </a-pagination>
            </div>
          </TableWrapper>
          <sdCards headless v-else>
            <a-skeleton active :paragraph="{ rows: 10 }" />
          </sdCards>
        </a-tab-pane>
        <a-tab-pane key="4" :tab="i18n.t('transactions.point.buys')" v-if="accountData.kind == 'point'">
          <TableWrapper class="table-data-view table-responsive">
            <a-table :dataSource="dataSourcePurchases" :pagination="false" :columns="columnsPurchases"> </a-table>
            <div style="text-align: right; margin-bottom: 20px">
              <a-pagination
                :default-page-size="pageSize"
                v-model:pageSize="pageSize"
                :show-size-changer="true"
                v-model:current="purchasesMeta.current"
                :total="purchasesMeta.count"
                @change="onChangePurchases"
              >
                <template #buildOptionText="props">
                  <span>{{ props.value }}/{{ i18n.t('start.page') }}</span>
                </template>
              </a-pagination>
            </div>
          </TableWrapper>
        </a-tab-pane>
      </a-tabs>
    </div>
    <a-spin v-else class="loading" size="large" />
    <ModalCash :visibleModal="visibleModalAction" @closeModal="closeModal" />
    <sdModal
      centered
      type="primary"
      :title="i18n.t('start.coinPaymentsDepositCheck')"
      :visible="visibleModalDeposit"
      :onCancel="closeModal"
    >
      <DepositCoinpayments :depositId="depositId" />
    </sdModal>
  </Main>
</template>

<script>
import { Main, TableWrapper } from '../styled';
import { useI18n } from 'vue-i18n';
import { onMounted, computed, defineAsyncComponent, ref, watch, watchEffect } from '@vue/runtime-core';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
const ModalCash = defineAsyncComponent(() => import('@/components/modals/ModalCash'));
const DepositCoinpayments = defineAsyncComponent(() => import('@/components/modals/overview/DepositCoinpayments'));
const TaskCard = defineAsyncComponent(() => import('@/components/elements/points/TaskCard'));

import { faSort, faSortUp, faSortDown } from '@fortawesome/free-solid-svg-icons';

const Session = {
  name: 'Session',
  components: {
    Main,
    TableWrapper,
    ModalCash,
    DepositCoinpayments,
    TaskCard,
  },
  setup() {
    const { state, dispatch } = useStore();
    const route = useRoute();
    const router = useRouter();
    const i18n = useI18n();

    const accountData = computed(() => state.accounts.accountData);
    const transactionsData = computed(() => state.accounts.transactionsData);
    const transactionsMeta = computed(() => state.accounts.transactionsMeta);
    const transfersData = computed(() => state.accounts.transfersData);
    const coinPaymentsData = computed(() => state.accounts.coinPaymentsData);
    const coinPaymentInfo = computed(() => state.accounts.coinPaymentInfo);
    const transfersMeta = computed(() => state.accounts.transfersMeta);
    const paymentsMeta = computed(() => state.accounts.paymentsMeta);
    const pageNum = computed(() => route.params.pageNum);
    const withdrawalsData = computed(() => state.accounts.withdrawalsData);
    const withdrawalsMeta = computed(() => state.accounts.withdrawalsMeta);
    const purchasesData = computed(() => state.shop.purchasesData);
    const purchasesMeta = computed(() => state.shop.purchasesMeta);
    const tasks = computed(() => state.shop.tasksData);

    const getableData = computed(() => {
      return {
        accId: route.params.accId,
        pageNum: pageNum.value,
        pageSize: pageSize.value,
        sortableLink: `&sort_by=${linkValue.value}&order=${order.value}`,
      };
    });

    const linkValue = ref('id');
    const order = ref('desc');
    let pageSize = ref(10);

    let accId = ref(route.params.accId);
    const visibleModalAction = ref(false);
    const visibleModalDeposit = ref(false);
    const depositId = ref(null);
    const transactionView = ref(true);
    const activeKey = ref('1');
    const activeKeyInfo = ref('4');
    const currentPage = ref(1);
    const isPc = computed(() => window.innerWidth > 500);

    const changeTab = (e) => {
      currentPage.value = e;
      if (e == 1) {
        onChangeTransactions(1);
      } else if (e == 2) {
        onChangePayments(1);
      } else if (e == 3) {
        onChangeWithdrawals(1);
      } else if (e == 4) {
        onChangePurchases(1);
      }
    };
    const closeModal = (closeWithoutPush) => {
      visibleModalAction.value = false;
      visibleModalDeposit.value = false;
      depositId.value = null;
      if (!closeWithoutPush) {
        router.push('/account/' + accId.value + '/page/' + pageNum.value);
      }
    };

    const openModal = (nameAction) => {
      router.push(`/account/${accId.value}/page/${pageNum.value}/${nameAction}`);
      visibleModalAction.value = true;
    };

    const openModalDeposit = (data) => {
      visibleModalDeposit.value = true;
      depositId.value = data;
      dispatch('getCoinpaymentsDepositInfo', data);
    };

    const sortTablePayments = (value) => {
      if (linkValue.value == value) {
        order.value == 'asc' ? (order.value = 'desc') : (order.value = 'asc');
      }
      linkValue.value = value;
      getableData.value.sortableLink = `&sort_by=${value}&order=${order.value}`;
      dispatch('getAllCoinpaymentsDeposits', getableData.value);
    };

    const onChangeTransactions = (page) => {
      getableData.value.pageNum = page;
      dispatch('getTransactions', getableData.value);
    };

    const onChangePayments = (page) => {
      getableData.value.pageNum = page;
      dispatch('getAllCoinpaymentsDeposits', getableData.value);
    };

    const onChangeWithdrawals = (page) => {
      getableData.value.pageNum = page;
      dispatch('getAllWithdrawals', getableData.value);
    };

    const onChangePurchases = (page) => {
      getableData.value.pageNum = page;
      dispatch('axiosPurchasesGetData', getableData.value);
    };

    watch(pageSize, () => {
      if (currentPage.value == 1) {
        onChangeTransactions(1);
      } else if (currentPage.value == 2) {
        onChangePayments(1);
      } else if (currentPage.value == 3) {
        onChangeWithdrawals(1);
      } else if (currentPage.value == 4) {
        onChangePurchases(1);
      }
    });

    const columnsTransactions = computed(() => [
      {
        title: i18n.t('transactions.created_at'),
        dataIndex: 'created_at',
        key: 'created_at',
      },
      {
        title: i18n.t('transactions.amount_operation'),
        dataIndex: 'amount_operation',
        key: 'amount_operation',
        slots: { customRender: 'amount_operation' },
      },
      {
        title: i18n.t('transactions.balance'),
        dataIndex: 'balance',
        key: 'balance',
      },
      {
        title: i18n.t('transactions.status'),
        dataIndex: 'status',
        key: 'status',
      },
      {
        title: i18n.t('transactions.operable_type'),
        dataIndex: 'operable_type_short',
        key: 'operable_type_short',
      },
      {
        title: i18n.t('transactions.comment'),
        dataIndex: 'operable_type',
        key: 'operable_type',
        slots: { customRender: 'operableType' },
      },
      {
        title: '',
        width: 0,
      },
    ]);

    const columnsPayments = computed(() => [
      {
        title: i18n.t('start.operation_id'),
        dataIndex: 'operation_id',
        key: 'operation_id',
        slots: { customRender: 'operation_id' },
      },
      {
        title: i18n.t('start.request_amount'),
        dataIndex: 'request_amount',
        key: 'request_amount',
        slots: { customRender: 'request_amount', title: 'customTitleSumm' },
      },
      {
        title: i18n.t('start.exchange_amount'),
        dataIndex: 'exchange_amount',
        key: 'exchange_amount',
        slots: { customRender: 'exchange_amount' },
      },
      {
        title: i18n.t('start.exchange_rate'),
        dataIndex: 'exchange_rate',
        key: 'exchange_rate',
        slots: { customRender: 'exchange_rate' },
      },
      {
        dataIndex: 'operation_status_text',
        key: 'operation_status_text',
        slots: { customRender: 'operation_status_text', title: 'customTitleStatus' },
      },
      {
        dataIndex: 'created_at',
        key: 'created_at',
        slots: { customRender: 'created_at', title: 'customTitleDate' },
      },
      {
        title: '',
        width: 0,
      },
    ]);

    const columnsWithdrawals = computed(() => [
      {
        title: i18n.t('transactions.wallet_name'),
        dataIndex: 'wallet',
        key: 'wallet',
      },
      {
        title: i18n.t('start.amountWithdrawal') + ' (' + i18n.t('start.commission') + ')',
        dataIndex: 'amount',
        key: 'amount',
      },

      {
        title: i18n.t('start.created_at'),
        dataIndex: 'created_at',
        key: 'created_at',
      },
      {
        title: i18n.t('transactions.status'),
        dataIndex: 'status',
        key: 'status',
      },
      {
        title: i18n.t('transactions.comment'),
        dataIndex: 'comment',
        key: 'comment',
      },
      {
        title: '',
        width: 0,
      },
    ]);

    const columnsPurchases = computed(() => [
      //{
      //  title: i18n.t('transactions.kind'),
      //  dataIndex: 'kind',
      //  key: 'kind',
      //},
      {
        title: i18n.t('transactions.amount'),
        dataIndex: 'amount',
        key: 'amount',
      },

      {
        title: i18n.t('tasks.data.created_at'),
        dataIndex: 'created_at',
        key: 'created_at',
      },
      {
        title: i18n.t('transactions.comment'),
        dataIndex: 'comment',
        key: 'comment',
      },
      {
        title: '',
        width: 0,
      },
    ]);

    const dataSourceTransactions = computed(() =>
      transactionsData.value
        ? transactionsData.value.map((transaction, key) => {
            const { attributes, id } = transaction;
            const {
              operable_type,
              amount_before,
              amount_after,
              amount_operation,
              operation_currency,
              created_at,
              status,
              operation_info,
              comment,
            } = attributes;
            return {
              key: key + 1,
              id,
              operable_type,
              balance: (
                <p style="display:flex;">
                  {' '}
                  {i18n.n(+amount_before, 'decimal')} {i18n.t('transactions.' + operation_currency)}{' '}
                  <span
                    style="margin:0 10px; white-space:nowrap;word-wrap:nowrap;"
                    class={+amount_after > +amount_before ? 'arrow-more' : 'arrow-less'}
                  >
                    ->
                  </span>{' '}
                  {i18n.n(+amount_after, 'decimal')} {i18n.t('transactions.' + operation_currency)}
                </p>
              ),
              operable_type_short: i18n.t('transactions.' + operable_type, 2),
              operation_currency: i18n.t('transactions.' + operation_currency),
              amount_operation: i18n.n(+amount_operation, 'decimal'),
              created_at: i18n.d(created_at, 'long'),
              status: i18n.t('transactions.' + status),
              normal_date: created_at,
              operation_info,
              comment,
            };
          })
        : [],
    );

    const dataSourcePayments = computed(() =>
      coinPaymentsData.value
        ? coinPaymentsData.value.map((payment, key) => {
            const {
              operation_id,
              request_amount,
              exchange_amount,
              exchange_rate,
              operation_status_text,
              active,
              created_at,
              exchange_currency,
              id,
            } = payment.attributes;
            return {
              key: key + 1,
              operation_id,
              request_amount,
              exchange_amount,
              exchange_currency,
              exchange_rate,
              operation_status_text,
              created_at,
              active,
              id,
            };
          })
        : [],
    );
    const dataSourceWithdrawals = computed(() =>
      withdrawalsData.value
        ? withdrawalsData.value.map((withdrawal, key) => {
            const {
              amount,
              exchange_amount,
              exchange_rate,
              priority,
              created_at,
              due_at,
              status,
              comment,
              commission,
              exchange_currency,
              wallet,
            } = withdrawal.attributes;
            return {
              key,
              wallet: wallet.data.attributes.name,
              amount: (
                <p>
                  {i18n.n(+amount, 'decimal')} ({i18n.n(+commission, 'decimal')}) USDT
                </p>
              ),
              exchange_amount: exchange_amount == null ? '' : exchange_amount + ' ' + exchange_currency,
              exchange_rate: exchange_rate == null ? '' : exchange_rate + ' ' + exchange_currency,
              priority: i18n.t('transactions.' + priority),
              created_at: i18n.d(created_at, 'short'),
              due_at: i18n.d(due_at, 'short'),
              status: i18n.t('editWalletView.' + status),
              comment,
            };
          })
        : [],
    );

    const dataSourcePurchases = computed(() => {
      return purchasesData.value.map((purchase, key) => {
        const { amount, amount_currency, comment, created_at } = purchase.attributes;
        return {
          key,

          amount: `${amount} ${amount_currency}`,
          comment,
          created_at: i18n.d(created_at, 'long'),
        };
      });
    });

    watchEffect(() => {
      if (route.params.accId !== accId.value && !route.params.actionType && route.params.accId) {
        accId.value = route.params.accId;
        dispatch('accountGetData', accId.value);
        dispatch('getTransactions', getableData.value);
        dispatch('axiosTasksGetData');
      }
    });
    onMounted(() => {
      route.params.actionType && !visibleModalAction.value
        ? router.push('/account/' + accId.value + '/page/' + pageNum.value)
        : '';
      if (accId.value) {
        dispatch('accountGetData', accId.value);
        dispatch('getTransactions', getableData.value);
        dispatch('axiosTasksGetData');
      }
    });
    return {
      i18n,
      accountData,
      visibleModalAction,
      columnsTransactions,
      transactionsData,
      dataSourceTransactions,
      transfersData,
      transactionView,
      coinPaymentsData,
      coinPaymentInfo,
      columnsPayments,
      dataSourcePayments,
      visibleModalDeposit,
      depositId,
      transfersMeta,
      paymentsMeta,
      pageNum,
      pageSize,
      faSort,
      faSortUp,
      faSortDown,
      order,
      linkValue,
      transactionsMeta,
      activeKey,
      withdrawalsData,
      withdrawalsMeta,
      dataSourceWithdrawals,
      columnsWithdrawals,
      purchasesMeta,
      columnsPurchases,
      purchasesData,
      dataSourcePurchases,
      isPc,
      activeKeyInfo,
      tasks,
      openModal,
      closeModal,
      openModalDeposit,
      sortTablePayments,
      onChangePayments,
      onChangeWithdrawals,
      onChangeTransactions,
      changeTab,
    };
  },
};
export default Session;
</script>
<style scoped lang="scss">
.account {
  &__title {
    @media screen and (max-width: 450px) {
      min-width: 150px;
      margin-right: 20px;
    }
  }

  &__button {
    margin-left: 20px;
    &:nth-child(1) {
      margin-left: 0;
    }
    @media screen and (max-width: 450px) {
      margin: 0;
      max-width: 45%;
    }
  }
  &__subtitle {
    margin-right: 60px;
    line-height: 1.4;
    color: #5f63f2;
    font-weight: 600;
    font-size: 18px;
    span {
      font-weight: 400;
      font-size: 12px;
      color: #5a5f7d;
    }
    margin-bottom: 0;
    @media screen and(max-width:500px) {
      text-align: center;
      margin: 0;
      margin-bottom: 20px;
    }
  }
}
.account__wrapper {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  & > p {
    margin-bottom: 0;
  }
  @media screen and(max-width:500px) {
    justify-content: center;
    max-width: 90vw !important;
    flex-wrap: nowrap;

    .account__buttons {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .account__subtitle {
      width: 100%;
    }
  }
}
.arrow-more {
  color: green;
}
.arrow-less {
  color: red;
}
.card {
  &__title {
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 0;
  }
  &__text {
    font-size: 16px;
    font-weight: 400;
  }
  &__wrapper {
    display: flex;
    align-items: center;
    > div {
      margin-right: auto;
    }
  }
}

.point__account {
  font-size: 18px;
  font-weight: 500;
}
</style>
